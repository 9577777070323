/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the eois reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';
import { Eoi } from '../api/eoi';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualEoisState {
    eois: Array<Eoi> | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IEoisState {
    byContext: Record<string, IIndividualEoisState>;
};

export interface IEoisStore {
    eois: IEoisState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualEoisState: IIndividualEoisState = {
    eois: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialEoisState: IEoisState = {
    byContext: {}
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class EoisReducer extends ImmerReducer<IEoisState> {
    public load(institutionCode?: string) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualEoisState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(institutionCode?: string, eois?: Array<Eoi>) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualEoisState };
        }

        this.draftState.byContext[context].eois = eois ? eois : null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(institutionCode?: string, responseStatus?: ResponseStatus) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualEoisState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(institutionCode?: string) {
        const context = `${institutionCode}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialEoisState };
    }
};

export const eoisActions = createActionCreators(EoisReducer);
export const eoisReducer = createReducerFunction(EoisReducer, initialEoisState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createEoisApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.EoiGetCollection());
    },
    loadByInstitutionCode: (institutionCode?: string) => {
        const request: Dtos.EoiGetCollectionByInstitutionCode = new Dtos.EoiGetCollectionByInstitutionCode({ institutionCode });

        return client.get(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createEoisLogic = (api: ReturnType<typeof createEoisApi>) => {
    const logic = {
        load: createLogic<IEoisStore, {}, undefined, string, ReturnType<typeof eoisActions.load>>({
            type: eoisActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const institutionCode = action.payload as unknown as string;

                try {
                    let response: Dtos.EoiCollectionResponse | null = null;

                    if (institutionCode) {
                        response = await api.loadByInstitutionCode(institutionCode);
                    } else {
                        response = await api.load();
                    }

                    dispatch(eoisActions.loadSuccess(
                        institutionCode,
                        response?.eois
                    ));
                }
                catch (error) {
                    dispatch(eoisActions.loadFailure(
                        institutionCode,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useEoisSelector: TypedUseSelectorHook<IEoisStore> = useSelector;

export const eoisSelectors = {
    eois: (state: IEoisStore, institutionCode?: string) => {
        const context = `${institutionCode}`;

        return state.eois?.byContext[context]?.eois ?? initialIndividualEoisState.eois;
    },
    loadState: (state: IEoisStore, institutionCode?: string) => {
        const context = `${institutionCode}`;

        return state.eois?.byContext[context]?.loadState ?? initialIndividualEoisState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerEoisReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createEoisApi(client);

    const logic = createEoisLogic(api);

    reducerRegistry.register('eois', eoisReducer as Reducer, logic as any);
};

export default registerEoisReducer;