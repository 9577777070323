/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the patient consent collapsible table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Link } from 'react-router-dom';

import { useParams, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { History as RouterHistory } from 'history';

import { Button, FormControl, InputLabel, Select, MenuItem, Grid, Tooltip, withStyles, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, IconButton, IconButtonProps } from '@material-ui/core';

import { Column } from 'material-table';

import { DateTime } from 'luxon';

import {
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    CollapsibleTable,
    ICollapsibleTableProps,
    IInstitution,
    OnlinePatientManagementContext,
    useSnackbar,
    ProgressButton,
    useAuthenticatedUser
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import AlertTitle from '@material-ui/lab/AlertTitle';
/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * Used for the face-to-face consent icon.
 */
import { faSyncAlt } from '@fortawesome/pro-duotone-svg-icons/faSyncAlt';
/**
 * Used for the remote consent icon.
 */
import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell';
/**
 * Used for the remote consent icon.
 */
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';
/**
 * Used for the sign consent icon.
 */
import { faPen } from '@fortawesome/pro-duotone-svg-icons/faPen';
/**
 * Used for the verify consent icon.
 */
import { faFileCheck } from '@fortawesome/pro-duotone-svg-icons/faFileCheck';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

import { PatientConsent } from '../api/patientConsent';

import { usePatientConsents, IUsePatientConsentsActions } from '../hooks/usePatientConsents';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import EoiDialog from './EoiDialog';
import { StatusTypeEnum, ConsentTypeEnum } from '../api/dtos';
import { Patient } from '@ngt/opms/dist/api/patient';
import PatientConsentDialog from './PatientConsentDialog';
import EConsentContext from '../context/EConsentContext';
import { InvestigatorsContext } from './EConsentPage'


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading' | 'columns'>;

interface IPatientConsentCollapsibleTableProps extends OmitData<ICollapsibleTableProps<PatientConsent>> {
    data?: Array<PatientConsent>;
    columns?: Array<Column<PatientConsent>>;
    title?: string;
    loading?: boolean;
}

interface IPatientConsentButtonProps extends IconButtonProps {
    icon: IconProp;
    action: () => void;
    tooltipTitle?: React.ReactNode;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    button: {
        padding: theme.spacing(0),
        marginLeft: theme.spacing(2),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.EConsentView,
    Permission.EConsentAdminister,
    Permission.EConsentManage
];


const usePatientConsentColumns = (
    canAdministerEConsent: boolean,
    canManageEConsent: boolean,
    institutions: Array<IInstitution>,
    showInstitution: boolean,
    setPatientConsentId: React.Dispatch<React.SetStateAction<number | undefined>>,
    setPatientConsentDialogMode: React.Dispatch<React.SetStateAction<string | undefined>>,
    setPatientConsentDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
    columns?: Array<Column<PatientConsent>>,
) => {
    const classes = useStyles();

    const investigatorsContext = React.useContext(InvestigatorsContext);

    const contextEConsent = React.useContext(EConsentContext);

    const onActionClick = React.useCallback((patientConsentId?: number, mode?: string) => {
        setPatientConsentId(patientConsentId);
        setPatientConsentDialogMode(mode);
        setPatientConsentDialogOpen(true);
    }, [setPatientConsentId, setPatientConsentDialogMode, setPatientConsentDialogOpen]);

    const patientConsentColumns = React.useMemo(() => {
        const actionColumns: Array<Column<PatientConsent>> = [];

        const defaultColumns: Array<Column<PatientConsent>> = [];

        if (canAdministerEConsent) {
            defaultColumns.push(
            //{
            //    field: 'eoi.invitation.emailAddress',
            //    title: 'Email Address',
            //    render: (patientConsent, type) => (patientConsent?.eoi?.invitation?.emailAddress),
            //},
            {
                field: 'eoi.firstName',
                title: 'First Name',
                    render: (patientConsent, type) => (patientConsent?.eoi?.firstName),
                    width: 50
            },
            {
                field: 'eoi.lastName',
                title: 'Last Name',
                    render: (patientConsent, type) => (patientConsent?.eoi?.lastName),
                    width: 50
            });
        }

        defaultColumns.push(
        {
            field: 'id',
            title: 'Date',
                render: (patientConsent, type) => ((patientConsent?.dateDocumentVerified ?? patientConsent?.dateInvestigatorSigned ?? patientConsent?.datePatientSigned ?? patientConsent?.dateStarted) ? DateTime.fromISO((patientConsent?.dateDocumentVerified ?? patientConsent?.dateInvestigatorSigned ?? patientConsent?.datePatientSigned ?? patientConsent?.dateStarted) as string).toFormat('dd/MM/yyyy, hh:mm a') : ''),
            width: 80
        },
        {
            field: 'status.value',
            title: 'Status',
            render: (patientConsent, type) => (patientConsent?.status?.value),
            width: 80
            });

        if (!!showInstitution) {
            defaultColumns.push({
                field: 'id',
                title: 'Institution',
                render: (patientConsent, type) => (<div className={classes.textWrap}>{institutions?.find(i => i.code === (patientConsent?.eoi?.invitation?.institutionCode))?.name}</div>),
                customSort: (data1, data2, type) => ((institutions.find(i => i.code === (data1?.eoi?.invitation?.institutionCode)) as IInstitution).name) < ((institutions.find(i => i.code === (data2?.eoi?.invitation?.institutionCode)) as IInstitution).name) ? -1 : 1,
            });
        }

        defaultColumns.push({
            field: 'id',
            title: 'Investigator',
            render: (consent, type) => (<div className={classes.textWrap}>{investigatorsContext.investigators?.find(i => i.username === (consent.eoi.invitation.investigator))?.name}</div>),
            customSort: (data1, data2, type) => ((investigatorsContext.investigators.find(i => i.username === (data1.eoi.invitation.investigator)) as any).name) < ((investigatorsContext.investigators!!.find(i => i.username === (data2.eoi.invitation.investigator)) as any).name) ? -1 : 1,
        });

        if (canAdministerEConsent || canManageEConsent) {
            actionColumns.push({
                field: 'id',
                title: 'Actions',
                render: patientConsent => {
                    return (
                        <>
                            {
                                canAdministerEConsent &&
                                <PatientConsentButton
                                    icon={faSyncAlt}
                                    action={() => { onActionClick(patientConsent?.id, "restart-consent") }}
                                    tooltipTitle="Restart Consent"
                                />
                            }
                            
                            {
                                canManageEConsent &&
                                !!patientConsent?.statusId &&
                                patientConsent.typeId === ConsentTypeEnum.Remote &&
                                [StatusTypeEnum.PatientSigning, StatusTypeEnum.InvestigatorSigning].includes(patientConsent?.statusId) && <PatientConsentButton
                                    icon={faBell}
                                    action={() => { onActionClick(patientConsent?.id, "send-reminder") }}
                                    tooltipTitle={
                                        <>
                                            Send Reminder
                                            {
                                                !!patientConsent?.dateNotificationSent && (
                                                    <>
                                                        <br />
                                                        {(patientConsent?.statusId === StatusTypeEnum.PatientSigning ? "Patient" : "Investigator")} was last notified on <i>{DateTime.fromISO(patientConsent.dateNotificationSent).toFormat('dd/MM/yyyy, hh:mm a')}</i>
                                                    </>
                                                )
                                            }
                                        </>
                                    }
                                />
                            }
                            {
                                canAdministerEConsent &&
                                patientConsent?.statusId === StatusTypeEnum.InvestigatorSigning && (
                                    <PatientConsentButton
                                        icon={faPen}
                                        action={() => { window.location.assign(contextEConsent.trialConsentSignedPageUrl+`${patientConsent?.guid}`); }}
                                        tooltipTitle="Sign Consent"
                                    />
                                )
                            }
                            {
                                canAdministerEConsent &&
                                patientConsent.typeId === ConsentTypeEnum.FaceToFace && 
                                patientConsent?.statusId === StatusTypeEnum.PatientSigning && (
                                    <PatientConsentButton
                                        icon={faPen}
                                        action={() => { window.location.assign(contextEConsent.trialConsentPageUrl + `${patientConsent?.guid}`); }}
                                        tooltipTitle="Sign Consent"
                                    />
                                )
                            }
                            {
                                canManageEConsent &&
                                patientConsent?.statusId === StatusTypeEnum.VerifyingDocument && (
                                    <PatientConsentButton
                                        icon={faFileCheck}
                                        action={() => { onActionClick(patientConsent?.id, "verify-consent") }}
                                        tooltipTitle="Verify Consent"
                                    />
                                )
                            }
                            {
                                canAdministerEConsent &&
                                patientConsent?.statusId === StatusTypeEnum.PatientSigning && (
                                    <PatientConsentButton
                                        icon={faTimes}
                                        action={() => { onActionClick(patientConsent?.id, "refused-consent") }}
                                        tooltipTitle="Refused Consent"
                                    />
                                )
                            }
                        </>
                    );
                },
                width: 100,
                sorting: false
            });
        }

        return [...(defaultColumns ?? columns), ...actionColumns];
    }, [showInstitution, institutions, canManageEConsent, canAdministerEConsent, classes, columns, investigatorsContext])
    
    return patientConsentColumns;
};

const PatientConsentButton: React.FunctionComponent<IPatientConsentButtonProps> = ({
    icon,
    action,
    tooltipTitle,
    ...iconButtonProps
}) => {
    const classes = useStyles();

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);

    return <>
        {
            !!tooltipTitle && <LightTooltip title={tooltipTitle ?? ''} className={classes.tooltip}>
                <IconButton
                    color="primary"
                    className={classes.button}
                    size="medium"
                    onClick={action}
                >
                    <FontAwesomeIcon icon={icon} fixedWidth />
                </IconButton>
            </LightTooltip>
        }

        {
            !tooltipTitle && <IconButton
                color="primary"
                className={classes.button}
                onClick={action}
            >
                <FontAwesomeIcon icon={icon} fixedWidth />
            </IconButton>
        }
    </>
};

const defaultInstitutions: Array<IInstitution> = [];

const PatientConsentCollapsibleTable = ({
    data,
    loading,
    title,
    columns,
    ...tableProps
}: IPatientConsentCollapsibleTableProps) => {

    const { institutionCode } = useParams<Record<string, string>>();

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.filter(i => i.code).find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [[canViewEConsent, canAdministerEConsent, canManageEConsent], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null);

    const titleToUse = title ?? 'Participant Consent';

    const [patientConsents, patientConsentsLoadState, patientConsentsActions] = usePatientConsents(institution?.code, true);

    const { investigator, setInvestigator, investigators, setInvestigators } = React.useContext(InvestigatorsContext);

    const patientConsentsToUse = React.useMemo(() => {
        if (!patientConsents && !data) {
            return [];
        }

        return (
            data ??
            patientConsents?.filter(p => (p.statusId === StatusTypeEnum.PatientSigning ||
                p.statusId === StatusTypeEnum.InvestigatorSigning ||
                p.statusId === StatusTypeEnum.VerifyingDocument ||
                p.statusId === StatusTypeEnum.Completed) &&
                (p.eoi.invitation.investigator == investigator?.username || (!investigator && investigators.find(i => p.eoi.invitation.investigator === i?.username)))) ??
            []
        );
    }, [patientConsents, data, investigator, investigators]);

    const patientConsentsLoadingToUse = React.useMemo(() => {
        return data === undefined && loading === undefined ?
            patientConsentsLoadState.state === RequestState.None || patientConsentsLoadState.state === RequestState.Pending || permissionLoadState.state === RequestState.Pending :
            loading ?? false;
    }, [data, loading, patientConsentsLoadState, permissionLoadState]);

    const [patientConsentDialogOpen, setPatientConsentDialogOpen] = React.useState(false);

    const [patientConsentDialogMode, setPatientConsentDialogMode] = React.useState<string | undefined>(undefined);

    const [patientConsentId, setpatientConsentId] = React.useState<number | undefined>(undefined);

    const columnsToUse = usePatientConsentColumns(
        canAdministerEConsent,
        canManageEConsent,
        institutions ?? defaultInstitutions,
        !institution,
        setpatientConsentId,
        setPatientConsentDialogMode,
        setPatientConsentDialogOpen,
        columns
    );

    const [tableCount, setTableCount] = React.useState(1);

    React.useEffect(() => {
        setTableCount(x => x + 1);
    }, [columnsToUse, patientConsentsToUse]);

    return (
        <>
            {
                <CollapsibleTable
                    key={tableCount}
                    title={titleToUse}
                    entityName="Participant Consent"
                    loading={patientConsentsLoadingToUse}
                    data={patientConsentsToUse}
                    columns={columnsToUse}
                    pluralizeTitle={false}
                    {...tableProps}
                />
            }

            {
                !!patientConsentId && !!patientConsentDialogMode && <PatientConsentDialog
                    patientConsentId={patientConsentId!!}
                    mode={patientConsentDialogMode as "restart-consent" | "send-reminder"  | "refused-consent"}
                    open={patientConsentDialogOpen}
                    setOpen={setPatientConsentDialogOpen}
                />
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default PatientConsentCollapsibleTable;
