/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the patient consents reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';
import { PatientConsent } from '../api/patientConsent';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualPatientConsentsState {
    patientConsents: Array<PatientConsent> | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IPatientConsentsState {
    byContext: Record<string, IIndividualPatientConsentsState>;
};

export interface IPatientConsentsStore {
    patientConsents: IPatientConsentsState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualPatientConsentsState: IIndividualPatientConsentsState = {
    patientConsents: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialPatientConsentsState: IPatientConsentsState = {
    byContext: {}
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class PatientConsentsReducer extends ImmerReducer<IPatientConsentsState> {
    public load(institutionCode?: string) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualPatientConsentsState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(institutionCode?: string, patientConsents?: Array<PatientConsent>) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualPatientConsentsState };
        }

        this.draftState.byContext[context].patientConsents = patientConsents ? patientConsents : null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(institutionCode?: string, responseStatus?: ResponseStatus) {
        const context = `${institutionCode}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualPatientConsentsState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(institutionCode?: string) {
        const context = `${institutionCode}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialPatientConsentsState };
    }
};

export const patientConsentsActions = createActionCreators(PatientConsentsReducer);
export const patientConsentsReducer = createReducerFunction(PatientConsentsReducer, initialPatientConsentsState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createPatientConsentsApi = (client: JsonServiceClient) => ({
    load: () => {
        return client.get(new Dtos.PatientConsentGetCollection());
    },
    loadByInstitutionCode: (institutionCode?: string) => {
        const request: Dtos.PatientConsentGetCollectionByInstitutionCode = new Dtos.PatientConsentGetCollectionByInstitutionCode({ institutionCode });

        return client.get(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createPatientConsentsLogic = (api: ReturnType<typeof createPatientConsentsApi>) => {
    const logic = {
        load: createLogic<IPatientConsentsStore, {}, undefined, string, ReturnType<typeof patientConsentsActions.load>>({
            type: patientConsentsActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const institutionCode = action.payload as unknown as string;

                try {
                    let response: Dtos.PatientConsentCollectionResponse | null = null;

                    if (institutionCode) {
                        response = await api.loadByInstitutionCode(institutionCode);
                    } else {
                        response = await api.load();
                    }

                    dispatch(patientConsentsActions.loadSuccess(
                        institutionCode,
                        response?.patientConsents
                    ));
                }
                catch (error) {
                    dispatch(patientConsentsActions.loadFailure(
                        institutionCode,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const usePatientConsentsSelector: TypedUseSelectorHook<IPatientConsentsStore> = useSelector;

export const patientConsentsSelectors = {
    patientConsents: (state: IPatientConsentsStore, institutionCode?: string) => {
        const context = `${institutionCode}`;

        return state.patientConsents?.byContext[context]?.patientConsents ?? initialIndividualPatientConsentsState.patientConsents;
    },
    loadState: (state: IPatientConsentsStore, institutionCode?: string) => {
        const context = `${institutionCode}`;

        return state.patientConsents?.byContext[context]?.loadState ?? initialIndividualPatientConsentsState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerPatientConsentsReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createPatientConsentsApi(client);

    const logic = createPatientConsentsLogic(api);

    reducerRegistry.register('patientConsents', patientConsentsReducer as Reducer, logic as any);
};

export default registerPatientConsentsReducer;