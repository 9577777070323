/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the function used to create the eoi reducer.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type the state of a request. 
 */
import { IRequestState, RequestState } from '@ngt/request-utilities';

/*
 * Used to create the reducer and associated actions.
 */
import { ImmerReducer, createReducerFunction, createActionCreators } from 'immer-reducer';

/*
 * Used to create side effects for the reducer.
 */
import { createLogic } from 'redux-logic';

/*
 * Used to type the reducer registry used to register reducers to the store.
 */
import { ReducerRegistry } from '@ngt/reducer-registry-logics';

/*
 * Used to create a typed selector hook.
 */
import { TypedUseSelectorHook, useSelector } from 'react-redux';

/*
 * Used to type the ServiceStack client.
 */
import { JsonServiceClient } from '@servicestack/client';

/*
 * Used to correctly type the created reducer.
 */
import { Reducer } from 'redux';

import { ResponseStatus } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to get access to the API types and requests
 */

import * as Dtos from '../api/dtos';
import { Eoi } from '../api/eoi';


/*
* ---------------------------------------------------------------------------------
* Interfaces / Types
* ---------------------------------------------------------------------------------
*/

export interface IIndividualEoiState {
    eoi: Eoi | null;
    loadState: IRequestState<ResponseStatus>;
};

export interface IEoiState {
    byContext: Record<string, IIndividualEoiState>;
};

export interface IEoiStore {
    eoi: IEoiState
}

/*
* ---------------------------------------------------------------------------------
* Initial State
* ---------------------------------------------------------------------------------
*/

export const initialIndividualEoiState: IIndividualEoiState = {
    eoi: null,
    loadState: {
        state: RequestState.None
    }
};

export const initialEoiState: IEoiState = {
    byContext: {}
}

/*
 * ---------------------------------------------------------------------------------
 * Helper Fuctions
 * ---------------------------------------------------------------------------------
 */

const createIdContext = (id?: number | null) => {
    return `${(id ?? 'new')}`;
}

/*
* ---------------------------------------------------------------------------------
* Reducer
* ---------------------------------------------------------------------------------
*/

export class EoiReducer extends ImmerReducer<IEoiState> {
    public load(id: number) {
        const context = `${id}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualEoiState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Pending
        };
    }

    public loadSuccess(id: number, eoi?: Eoi) {
        const context = `${id}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualEoiState };
        }

        this.draftState.byContext[context].eoi = eoi ?? null;

        this.draftState.byContext[context].loadState = {
            state: RequestState.Success
        };
    }

    public loadFailure(id: number, responseStatus?: ResponseStatus) {
        const context = `${id}`;

        if (!this.draftState.byContext[context]) {
            this.draftState.byContext[context] = { ...initialIndividualEoiState };
        }

        this.draftState.byContext[context].loadState = {
            state: RequestState.Failure,
            responseStatus
        };
    }

    public clear(id: number) {
        const context = `${id}`;

        if (this.draftState.byContext[context]) {
            delete this.draftState.byContext[context];
        }
    }

    public clearAll() {
        this.draftState = { ...initialEoiState };
    }
};

export const eoiActions = createActionCreators(EoiReducer);
export const eoiReducer = createReducerFunction(EoiReducer, initialEoiState);

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

const createEoiApi = (client: JsonServiceClient) => ({
    load: (id: number) => {
        const request: Dtos.EoiGetSingleById = new Dtos.EoiGetSingleById({
            id
        });
        return client.get(request);
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Logic
 * ---------------------------------------------------------------------------------
 */

const createEoiLogic = (api: ReturnType<typeof createEoiApi>) => {
    const logic = {
        load: createLogic<IEoiStore, {}, undefined, string, ReturnType<typeof eoiActions.load>>({
            type: eoiActions.load.type,
            process: async ({ action }, dispatch, done) => {
                const id = action.payload as unknown as number;

                try {
                    let response: Dtos.EoiSingleResponse | null = null;

                    response = await api.load(id);

                    dispatch(eoiActions.loadSuccess(
                        id,
                        response.eoi
                    ));
                }
                catch (error) {
                    dispatch(eoiActions.loadFailure(
                        id,
                        error ? error.responseStatus : undefined
                    ));
                }

                done();
            }
        })
    }

    return [
        logic.load,
    ]
};

/*
* ---------------------------------------------------------------------------------
* Selectors
* ---------------------------------------------------------------------------------
*/

export const useEoiSelector: TypedUseSelectorHook<IEoiStore> = useSelector;

export const eoiSelectors = {
    eoi: (state: IEoiStore, id?: number) => {
        const context = `${id}`;

        return state.eoi?.byContext[context]?.eoi ?? initialIndividualEoiState.eoi;
    },
    loadState: (state: IEoiStore, id?: number) => {
        const context = `${id}`;

        return state.eoi?.byContext[context]?.loadState ?? initialIndividualEoiState.loadState;
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

const registerEoiReducer = (client: JsonServiceClient, reducerRegistry: ReducerRegistry) => {
    const api = createEoiApi(client);

    const logic = createEoiLogic(api);

    reducerRegistry.register('eoi', eoiReducer as Reducer, logic as any);
};

export default registerEoiReducer;