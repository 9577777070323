/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../../../api/dtos';

import FieldGroupContext from '../../../../contexts/form/FieldGroupContext';
import FieldProvider from '../../FieldProvider';
import FormLabel from '../../FormLabel';
import { isString } from 'lodash-es';
import FormErrorHandler from '../../FormErrorHandler';
import FormErrorDisplay from '../../FormErrorDisplay';
import FieldErrorFlag from '../../FieldErrorFlag';
import Input, { IInputProps } from '../../Input';
import { InputComponentType, OmitInputRender } from '../../../../form/components/Input';
import { Table, TableHead, TableCell, TableBody, TableRow, Button } from '@material-ui/core';
import FormGridHeader, { IFormGridHeaderProps } from './FormGridHeader';
import { IFormGridCellProps } from './FormGridCell';
import FormGridRow from './FormGridRow';
import useFieldState from '../../../../form/hooks/useFieldState';
import IDtoClass from '../../../../utilities/IDtoClass';
import useFieldActions from '../../../../form/hooks/useFieldActions';
import ConditionContext from '../../../../form/contexts/ConditionContext';
import useFormState from '../../../../form/hooks/useFormState';
import FormOptionsContext from '../../../../contexts/form/FormOptionsContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IFormGridProps<TValues extends Object = any> {
    type: IDtoClass<TValues>;
    name: string;
    columns: IFormGridCell<TValues>[];
    noErrors?: boolean;
    minRow?: number;
    onAdd?: (values: TValues[] | null | undefined) => TValues[] | null | undefined;
    onDelete?: (values: TValues[] | null | undefined, index: number) => TValues[] | null | undefined;
    allowDelete?: (values: TValues[] | null | undefined, index: number) => boolean;
    allowAdd?: boolean;
    onRowClick?: (index: number) => void;
    onAddClick?: (index: number) => void;
    hideRemoveColumn?: boolean;
}

export interface IFormGridCell<TValues extends Record<string, any> = any> extends Omit<IFormGridCellProps, 'name'> {
    name: Extract<keyof TValues, string>;
    header?: React.ReactNode;
    headerClassName?: string;
    minWidth?: number;
    width?: number;
    maxWidth?: number;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    container: {
        overflowX: 'auto'
    },
    table: {
        minWidth: '100%'
    },
    addButton: {
        textAlign: 'right',
        padding: theme.spacing(3)
    },
    removeCell: {
        width: 95
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */


const FormGrid = <TValues extends Object = any>({
    type,
    name,
    columns,
    noErrors,
    minRow,
    onAdd,
    onDelete,
    allowDelete,
    allowAdd,
    onRowClick,
    onAddClick,
    hideRemoveColumn
}: IFormGridProps<TValues>) => {
    const calculatedColumns: Array<IFormGridCell> = React.useMemo(() => {
        return columns?.filter(column => !!column.name) ?? [];
    }, [columns])

    return (
        <FormErrorHandler
            forceNew
        >
            <FieldProvider name={name}>
                <FormGridInternal
                    type={type}
                    name={name}
                    columns={calculatedColumns}
                    noErrors={noErrors}
                    minRow={minRow}
                    onAdd={onAdd}
                    onDelete={onDelete}
                    allowDelete={allowDelete}
                    allowAdd={allowAdd}
                    onRowClick={onRowClick}
                    onAddClick={onAddClick}
                    hideRemoveColumn={hideRemoveColumn}
                />
            </FieldProvider>
        </FormErrorHandler>
    )
}

const FormGridInternal = <TValues extends Object = any>({
    type,
    name,
    columns,
    noErrors,
    minRow,
    onAdd,
    onDelete,
    allowDelete,
    allowAdd,
    onRowClick,
    onAddClick,
    hideRemoveColumn
}: IFormGridProps<TValues>) => {
    const classes = useStyles();

    const { value } = useFieldState<TValues[], Dtos.IValidationError>({ value: true });
    const { setValue, getValue } = useFieldActions<TValues[], Dtos.IValidationError>();

    const addRow = React.useCallback(() => {
        if (onAdd) {
            setValue(onAdd(value));
        } else if (value) {
            setValue([...value, new type({})]);
        } else {
            setValue([new type({})]);
        } 

        if (onAddClick && getValue() != null) {
            onAddClick((getValue() as TValues[]).length-1);
        }

    }, [setValue, value, onAdd, onAddClick, getValue]);

    const removeRow = React.useCallback((index: number) => {
        if (onDelete) {
            setValue(onDelete(value, index));
            return;
        }

        if (!value) {
            return;
        }

        if (index >= value.length) {
            return;
        }

        setValue(value.filter((v, i) => index !== i));
    }, [setValue, value, onDelete]);

    const allowRemoveRow = React.useCallback((index: number) => {
        if (allowDelete) {
            return allowDelete(value, index);
        }

        return true;
    }, [value, allowDelete])

    React.useEffect(() => {
        if (!value && minRow) {
            let newValues: TValues[]|null|undefined = [];

            for (var i = 0; i < minRow; i++) {
                if (onAdd) {
                    newValues = onAdd(newValues);
                    continue;
                }

                if (newValues) {
                    newValues = [...newValues, new type({})];
                    continue;
                }

                newValues = [new type({})];
            }

            setValue(newValues);
        }
    }, [value, minRow, setValue]);

    const conditionContext = React.useContext(ConditionContext);
    const formOptionsContext = React.useContext(FormOptionsContext);
    const { submitting } = useFormState({ submitting: true });

    const disabled = React.useMemo(() => {
        return submitting || conditionContext?.conditionMet === false || formOptionsContext.readOnly;
    }, [conditionContext?.conditionMet, submitting, formOptionsContext]);

    const allowAddComputed = allowAdd ?? true; 

    return (
        <div
            className={classes.container}
        >
            <Table
                className={classes.table}
            >
                <TableHead>
                    <TableRow>
                        {
                            columns?.map((column, i) => {
                                return (
                                    <FormGridHeader
                                        key={i}
                                        name={column.name}
                                        content={column.header}
                                        className={column.headerClassName}
                                        maxWidth={column.maxWidth}
                                        minWidth={column.minWidth}
                                        width={column.width}
                                        hidden={column.hidden}
                                    />
                                );
                            })
                        }
                        {
                            !disabled &&
                            !hideRemoveColumn && (
                                <TableCell className={classes.removeCell} />
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        value?.map((value, i) => {
                            return (
                                <FormGridRow
                                    key={i}
                                    removeRow={removeRow}
                                    cells={columns}
                                    index={i}
                                    noErrors={noErrors}
                                    disabled={disabled}
                                    allowDelete={allowRemoveRow}
                                    onClick={onRowClick}
                                    hideRemoveColumn={hideRemoveColumn}
                                />
                            );
                        })
                    }
                </TableBody>
            </Table>
            <FormErrorDisplay />
            {
                !disabled &&
                allowAddComputed &&
                (
                    <div
                        className={classes.addButton}
                    >
                        <Button
                            variant="contained"
                            type="button"
                            color="primary"
                            onClick={addRow}
                        >
                            Add
                        </Button>
                    </div>
                )
            }
        </div>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FormGrid;
