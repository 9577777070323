
/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

import React from "react";

export interface IEConsentContext {
    trialInfoPageUrl: string;
    trialConsentPageUrl: string;
    trialConsentSignedPageUrl: string;
    tissueBanking: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const EConsentContext = React.createContext<IEConsentContext>({
    trialInfoPageUrl: "/",
    trialConsentPageUrl: "/",
    trialConsentSignedPageUrl: "/",
    tissueBanking: false
})

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default EConsentContext;