/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the not refused eoi / consent collapsible table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Link } from 'react-router-dom';

import { useParams, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { History as RouterHistory } from 'history';

import { Button, FormControl, InputLabel, Select, MenuItem, Grid, Tooltip, withStyles, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';

import { Column } from 'material-table';

import { DateTime } from 'luxon';

import {
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    CollapsibleTable,
    ICollapsibleTableProps,
    IInstitution,
    OnlinePatientManagementContext,
    useSnackbar,
    ProgressButton,
    useAuthenticatedUser
} from '@ngt/opms';

import { usePermissionsByIds, Permission } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import AlertTitle from '@material-ui/lab/AlertTitle';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { useEois, IUseEoisActions } from '../hooks/useEois';
import RefusedConsentDialog from './RefusedConsentDialog';
import { usePatientConsents } from '../hooks/usePatientConsents';
import { InvestigatorsContext } from './EConsentPage'

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type OmitData<T extends { data: any }> = Omit<T, 'data' | 'title' | 'loading' | 'columns'>;

export interface IRefusedConsent {
    id: number,
    emailAddress: string,
    firstName: string,
    lastName: string,
    institutionCode: string,
    type: "eoi" | "consent",
    investigator: string
}

interface IRefusedConsentCollapsibleTableProps extends OmitData<ICollapsibleTableProps<IRefusedConsent>> {
    data?: Array<IRefusedConsent>;
    loading?: boolean;
    title?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    dialogContent: {
        padding: theme.spacing(2,3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.EConsentView,
    Permission.EConsentAdminister,
];

const useColumns = (
    canAdministerEConsent: boolean,
    classes: Record<string, string>,
    showInstitution: boolean,
    institutions: Array<IInstitution>,
    setType: React.Dispatch<React.SetStateAction<string | undefined>>,
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setId: React.Dispatch<React.SetStateAction<number | undefined>>
) => {

    const investigatorsContext = React.useContext(InvestigatorsContext);

    const onActionClick = React.useCallback((id?: number, type?: string) => {
        setId(id);
        setType(type);
        setDialogOpen(true);
    }, [setId, setType, setDialogOpen]);

    const columnsToUse = React.useMemo(() => {
        const actionColumns: Array<Column<IRefusedConsent>> = [];

        const columns: Array<Column<IRefusedConsent>> = [];

        if (canAdministerEConsent) {
            columns.push(
            //{
            //    field: 'emailAddress',
            //    title: 'Email Address',
            //    render: (data, type) => (data?.emailAddress),
            //},
            {
                field: 'firstName',
                title: 'First Name',
                render: (data, type) => (data?.firstName),
                width: 50
            },
            {
                field: 'lastName',
                title: 'Last Name',
                render: (data, type) => (data?.lastName),
                width: 50
            });
        }

        columns.push({
                field: 'type',
                title: 'Type',
                render: (data, type) => (data?.type === "eoi" ? "Not Interested" : "Refused Consent"),
        });

        if (!!showInstitution) {
            columns.push({
                field: 'institutionCode',
                title: 'Institution',
                render: (data, type) => (<div className={classes.textWrap}>{institutions?.find(i => i.code === (data?.institutionCode))?.name}</div>),
                customSort: (data1, data2, type) => !!institutions && ((institutions.find(i => i.code === (data1?.institutionCode)) as IInstitution).name) < ((institutions.find(i => i.code === (data2?.institutionCode)) as IInstitution).name) ? -1 : 1,
            });
        }

        columns.push({
            field: 'id',
            title: 'Investigator',
            render: (data, type) => (<div className={classes.textWrap}>{investigatorsContext.investigators?.find(i => i.username === (data.investigator))?.name}</div>),
            customSort: (data1, data2, type) => ((investigatorsContext.investigators.find(i => i.username === (data1.investigator)) as any).name) < ((investigatorsContext.investigators!!.find(i => i.username === (data2.investigator)) as any).name) ? -1 : 1,
        });

        if (canAdministerEConsent) {
            actionColumns.push({
                field: 'id',
                title: 'Actions',
                render: data => {
                    return (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            size="small"
                            onClick={() => { onActionClick(data.id, data.type) }}
                        >
                            Reset
                        </Button>
                    );
                },
                width: 100,
                sorting: false
            });
        }

        return [...columns, ...actionColumns];
    }, [showInstitution, institutions, canAdministerEConsent, classes, onActionClick, investigatorsContext])

    return columnsToUse;
};

const defaultInstitutions: Array<IInstitution> = [];

const RefusedConsentCollapsibleTable: React.FunctionComponent<IRefusedConsentCollapsibleTableProps> = ({
    data,
    loading,
    title,
    ...tableProps
}: IRefusedConsentCollapsibleTableProps) => {

    const classes = useStyles();

    const { institutionCode } = useParams<Record<string, string>>();

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.filter(i => i.code).find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [[canViewEConsent, canAdministerEConsent], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null);

    const [eois, eoisLoadState, eoisActions] = useEois(institution?.code);

    const [patientConsents, patientConsentsLoadState, patientConsentsActions] = usePatientConsents(institution?.code);

    const { investigator, setInvestigator, investigators, setInvestigators } = React.useContext(InvestigatorsContext);

    const eoisToUse = React.useMemo(() => {
        const notInterestedEois = eois?.filter(e => e.statusId === Dtos.StatusTypeEnum.NotInterested &&
            (e.invitation.investigator == investigator?.username || (!investigator && investigators.find(i => e.invitation.investigator === i?.username)))
        );

        if (!notInterestedEois) {
            return [];
        }

        return notInterestedEois.map(e => {
            const refusedEois: IRefusedConsent = {
                id: e.id!,
                emailAddress: e?.invitation?.emailAddress,
                firstName: e?.firstName,
                lastName: e?.lastName,
                institutionCode: e?.invitation?.institutionCode,
                type: "eoi",
                investigator: e?.invitation?.investigator
            }

            return refusedEois;
        });
    }, [eois, investigator, investigators]);

    const patientConsentsToUse = React.useMemo(() => {
        const refusedConsents = patientConsents?.filter(p => p.statusId === Dtos.StatusTypeEnum.Refused &&
            (p.eoi.invitation.investigator == investigator?.username || (!investigator && investigators.find(i => p.eoi.invitation.investigator === i?.username)))
        );

        if (!refusedConsents) {
            return [];
        }

        return refusedConsents.map(c => {
            const refusedEois: IRefusedConsent = {
                id: c.id!,
                emailAddress: c?.eoi?.invitation?.emailAddress,
                firstName: c?.eoi?.firstName,
                lastName: c?.eoi?.lastName,
                institutionCode: c?.eoi?.invitation?.institutionCode,
                type: "consent",
                investigator: c?.eoi?.invitation?.investigator
            }

            return refusedEois;
        });
    }, [patientConsents, investigator, investigators]);

    const dataToUse = React.useMemo(() => {
        if (!eoisToUse && !data) {
            return [];
        }

        return (data ?? [...eoisToUse, ...patientConsentsToUse]);
    }, [eoisToUse, patientConsentsToUse, data]);

    const titleToUse = title ?? 'Not Interested / Refused Consent';

    const loadingToUse = React.useMemo(() => {
        return data === undefined && loading === undefined ?
            eoisLoadState.state === RequestState.None || eoisLoadState.state === RequestState.Pending || patientConsentsLoadState.state === RequestState.None || patientConsentsLoadState.state === RequestState.Pending || permissionLoadState.state === RequestState.Pending :
            loading ?? false;
    }, [data, loading, eoisLoadState, patientConsentsLoadState, permissionLoadState]);

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [type, setType] = React.useState<string | undefined>(undefined);

    const [id, setId] = React.useState<number | undefined>(undefined);

    const columnsToUse = useColumns(
        canAdministerEConsent,
        classes,
        !institution,
        institutions ?? defaultInstitutions,
        setType,
        setDialogOpen,
        setId
    );

    const [tableCount, setTableCount] = React.useState(1);

    React.useEffect(() => {
        setTableCount(x => x + 1);
    }, [columnsToUse, dataToUse]);

    return (
        <>
            <CollapsibleTable
                key={tableCount}
                title={titleToUse}
                entityName="EOI/Consent"
                loading={loadingToUse}
                data={dataToUse}
                columns={columnsToUse}
                {...tableProps}
            />

            {
                !!id && !!type && <RefusedConsentDialog
                    refusedConsentId={id}
                    type={type as "eoi" | "consent"}
                    open={dialogOpen}
                    setOpen={setDialogOpen}
                />
            }
        </>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default RefusedConsentCollapsibleTable;
