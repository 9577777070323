/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a hook that proxies a hook from 
 * online-patient-management-reducers making less types required to use the hook.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import FieldGroupContext from '../../contexts/form/FieldGroupContext';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */
export interface IFieldGroupProps extends IFieldGroupStyleProps {
}

interface IFieldGroupStyleProps {
    color?: 'Auto' | 'Inherit' | 'None';
    paddingTop?: boolean | number;
    paddingBottom?: boolean | number;
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme, IFieldGroupStyleProps>(theme => ({
    container: {
        borderBottom: props => props.color === 'Auto' || !props.color ? `1px solid ${theme.palette.grey[300]}` : undefined,
        borderTop: props => props.color === 'Auto' || !props.color ? `1px solid ${theme.palette.grey[300]}` : undefined,
        marginTop: props => props.color === 'Auto' || !props.color ? -1 : undefined,

        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),

        background: 'inherit',

        //paddingTop: props => props.paddingTop === false ?
        //    theme.spacing(0) :
        //    props.paddingTop === true || props.paddingTop === undefined ?
        //        theme.spacing(2) :
        //        theme.spacing(props.paddingTop),
        //paddingBottom: props => props.paddingBottom === false ?
        //    theme.spacing(0) :
        //    props.paddingBottom === true || props.paddingBottom === undefined ?
        //        theme.spacing(2) :
        //        theme.spacing(props.paddingBottom),

        '&:nth-child(even)': {
            background: props => props.color === 'Auto' || !props.color ? theme.palette.grey[100] : undefined
        }
    }
}));


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const FieldGroup: React.FunctionComponent<IFieldGroupProps> = ({
    color,
    paddingTop,
    paddingBottom,
    children
}) => {
    const classes = useStyles({ color, paddingBottom, paddingTop });

    return (
        <div
            className={classes.container}
        >
            <FieldGroupContext.Provider value={true}>
                {children}
            </FieldGroupContext.Provider>
        </div>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default FieldGroup;
